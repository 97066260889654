.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.txt-left {
  text-align: left;
}

.btn-right {
  text-align: right;
}

.upload-header {
  font-weight: bold;
}

.notValidLi {
  flex-direction: column;
  font-weight: bold;
}

/* FILE UPLOAD */
.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 250px;
  border: 1px dashed #968A83;
  padding: 2rem;
}

.dropzone-highlight {
  border-color: blue;
}

h5.customFileUploadFontColor {
  color: #000;
  font-family: Gotham Narrow, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.active {
  background-color: #5f5959;
}

button {
  padding: 1rem;
  margin: 1rem;
  font-size: medium;
}


.list-item {
  display: flex;
  padding: 0;
  margin: 8px 0;
  min-width: 300px;
  font-size: 22px;
  background-color: #d4d2d2;
  cursor: grab;
  border-radius: 10px;
}

.list-item>h3 {
  padding: 10px;
  margin: 0;
}

.list-item>span {
  margin-right: 10px;
  font-size: 24px;
  font-weight: bold;
  border-right: 2px solid white;
  padding: 10px;
  padding-right: 16px;
}

.next-position {
  border: 2px solid #34d44f;
  opacity: 0.2;
}

.uploads {
  height: 250px;
  padding: 2rem;
  border-radius: 4px;
  border: 1px dashed #968A83;
}

.brand-image {
  width: 130px;
  height: 40px;
  flex-shrink: 0;
}

[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  background: white;
  /*vertical-align: middle;*/
  margin-right: 1em;
  color: black;
}

.upload-file-Btn {
  border-radius: 84px;
}

.dropdown {
  position: relative;
}

.menu {
  position: absolute;

  list-style-type: none;
  margin: 5px 0;
  padding: 0;

  border: 1px solid grey;
  width: 63px;
}

.menu>li {
  margin: 0;

  background-color: white;
}

.menu>li:hover {
  background-color: lightgray;
}

.menu>li>button {
  width: 100%;
  height: 100%;
  text-align: left;

  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

#sideBarCollapseContent {
  margin: 0;
  padding: 0;
}

#sidebar {
  transition: width 2s ease;
}

.divTable {
  display: table;
  width: auto;
  border-spacing: 0px;
  font-size: 12px;
  padding: 0px 5px 5px 0px;
}

.bg-gray {
  background-color: #eee;
}

.divCellHeader {
  font-weight: bold;
  background-color: #CBC4BC;
  float: left;
  display: table-column;
  padding: 5px;
  width: 128px;
  border: 1px solid #CBC4BC;
  color: #000;
  border-bottom: 2px solid #000;
}

.divMainCellHeader {
  font-weight: bold;
  background-color: #968A83;
  float: left;
  display: table-column;
  padding: 5px;
  width: 50%;
  height: 35px;
  color: #FFF;
  border-bottom: 2px solid #000;
  text-align: left;
}

.divRow {
  display: table-row;
  width: auto;
}

.divCell {
  float: left;
  display: table-column;
  width: 128px;
  padding: 5px;
  background-color: #FFF;
  border: 0px solid #CBC4BC;
  color: #000;
  border-bottom: 2px solid #CBC4BC;
}

.divMainCell {
  float: left;
  display: table-column;
  width: 50%;
  padding: 5px;
  background-color: #FFF;
  color: #000;
  border-bottom: 2px solid #CBC4BC;
  text-align: left;
}

.switchRow {
  background-color: #000;
  width: 261px;
  padding: 5px;
  margin: 5px 25px 5px 5px;
  height: 50px;
  cursor: pointer
}

.leftGridRow {
  margin: 0px 5px 0 5px
}

.ag-header {
  height: 30px !important;

  font-weight: bold;
  font-size: 12px;
}

.ag-theme-alpine {
  /* disable all borders */
  --ag-borders: none;
  /* then add back a border between rows */
  --ag-row-border-style: dashed;
  --ag-row-border-width: 5px;
  --ag-row-border-color: rgb(150, 150, 200);

  --ag-header-height: 25px;
  --ag-header-foreground-color: white;
  --ag-header-background-color: #CBC4BC !important;
  --ag-header-column-separator-width: 0px;
  --ag-secondary-border-color: #CBC4BC !important;
  --ag-range-selection-background-color: rgba(24, 34, 43, 0.4) !important;
  --ag-range-selection-background-color-2: rgba(24, 34, 43, 0.4) !important;
  --ag-range-selection-background-color-3: rgba(24, 34, 43, 0.4) !important;
  --ag-range-selection-background-color-4: rgba(24, 34, 43, 0.4) !important;
  --ag-alpine-active-color: #000 !important;

}

.ag-theme-alpine .ag-header-group-cell {
  font-weight: normal;
  font-size: 10px;
}

.btn-light,
.btn-dark {
  border: 1px solid #000;
  border-radius: 30px;
}

.btn-dark {
  width: 170px
}



.ag-header-viewport .ag-header-container .ag-header-row-column {
  background-color: #cbc4bc;
  border-bottom: 2px solid #000;
}

.ag-header-row-column .ag-column-hover {
  background-color: #cbc4bc;
  border-bottom: 2px solid #000;
}

.ag-header-viewport .ag-header-container .ag-header-row-column .ag-column-hover {
  background-color: #cbc4bc;
}

.ag-header-viewport .ag-header-container .ag-header-row-column-filter {
  background-color: #e4e4e4;
}

.ag-floating-filter,
.ag-header-viewport .ag-header-container .ag-header-row-column-filter .ag-floating-filter {
  background-color: #e4e4e4;
}

.ag-header-cell-text {
  font-weight: bold;
  color: #000;
}

.ag-ltr .ag-floating-filter-button {
  margin: 0px !important;
}

.ag-cell-not-inline-editing,
.ag-cell-value {
  text-align: left !important;
}

/* .ag-header-cell .ag-focus-managed {
  border-top: 2px solid #000 !important;
} */

.selectrfp {
  border-radius: 30px;
  padding: 10px;
  width: 150px;
  background-color: #000;
  color: #FFF
}

.selectrfp:focus {
  border-width: 4px;
  border-color: #7D9DFF
}

.editBox {
  width: 120px;
  font-weight: bold;
}

.editinput {
  width: 200px;
  height: 35px;
}

.dataRow,
.edit_conf {
  margin-top: 20px;
}

.editBoxLarge {
  width: 750px;
}

.btn_edit_dark {
  width: 100px
}

.edit_comments {
  width: 700px;
  height: 80px;
}

.editBox,
.edit_comments {
  font-size: 12px;
}

.editBoxError {
  color: red;
  font-weight: normal;
  font-size: 12px;
}

/*.react-datepicker-popper {*/
/*  z-index: 1000 !important;*/
/*  position: absolute;*/
/*  top: -220px !important;*/
/*}*/

.editBoxError {
  position: relative;
  z-index: 1001;
  margin-top: 25px;
}

#calDPID,
.react-datepicker__input-container,
.react-datepicker-wrapper {
  width: 100%;
  height: 100%;
}

/*.react-datepicker__calendar-icon {*/
/*  position: absolute;*/
/*  right: 8px;*/
/*  top: 50%;*/
/*  transform: translateY(-50%);*/
/*}*/

.react-datepicker__calendar-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  pointer-events: auto;
}

.datepicker {
  width: 100%;
  height: 35px;
  padding: 5px 30px 5px 10px;
  border: 0.5px solid #000;
  background: #fff;
  color: #000;
  display: block;
}


/*.datePickerHolder {*/
/*  height: 35px;*/
/*  width: 200px;*/
/*  position: relative;*/
/*}*/

.datePickerHolder {
  height: 35px;
  width: 200px;
  position: relative;
  z-index: 1;
}

/*!* Update existing datePickerHolder class *!*/
/*.datePickerHolder {*/
/*  height: 35px;*/
/*  width: 200px;*/
/*  position: relative;*/
/*  margin-bottom: 20px;*/
/*}*/

.dt_table .dt_column {
  width: 200px;
  float: left;
  text-align: left;
  padding-bottom: 15px;
}

.dt_table .dt_column.first {
  width: 80px;
  clear: left;
}

.clear {
  clear: both;
}

.dt_input {
  width: 20px;
  border: 0px;
  margin-left: -24px;
}

.react-confirm-alert-overlay {
  background: rgba(255, 255, 255, 0.5) !important;
}

.react-draggable, .react-draggable-dragged, .edit_comments, .editinput, .css-13cymwt-control, .css-13cymwt-control {
  background: rgba(255, 255, 255, 0.5) !important;
}

